import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';
import {
  AuthenticationService,
  I18nService
} from './services';
import { CommonModule } from '@angular/common';
import { NbAuthModule } from '@nebular/auth';
import { NbSecurityModule } from '@nebular/security';
import { AuthGuard } from './services/authentication';
import { Ng2SmartTableModule } from 'ng2-smart-table';

export const NB_CORE_PROVIDERS = [
  ...NbAuthModule.forRoot().providers,
  NbSecurityModule.forRoot().providers,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    I18nService,
    AuthenticationService,
    AuthGuard,
  ],
  exports: [
    NbAuthModule,
    NbSecurityModule,
    Ng2SmartTableModule,
  ],
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() core: CoreModule ) {
    if (core) {
        throw new Error('You should import core module only in the root module');
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    } as ModuleWithProviders;
  }
}
