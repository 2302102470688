<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" (click)="navigateHome()"><img src="../../../../assets/rigow.png" style="width: 135px;"> </a>
  </div>
</div>

<nb-actions size="medium">
  <nb-action>
    <a (click)="onLogout()">Logout</a>
  </nb-action>
</nb-actions>
