<span class="created-by"
  >Created with <span style="color: red">♥</span> by
  <b><a href="https://www.baianat.com/" target="_blank">Baianat</a></b>
  2021</span
>
<div class="socials">
  <a
    href="https://www.facebook.com/baianat/"
    target="_blank"
    class="ion ion-social-facebook"
  ></a>
</div>
