import gql from "graphql-tag";

export const logIn = gql`
mutation login($input: EmailOrPhonePasswordLoginInput!) {
  emailOrPhonePasswordLogin(input: $input) {
    data {
      id
      token
      email
      role {
        name
        description
        permissions
      }
    }
    code
    success
    message
  }
}
`;
