import gql from "graphql-tag";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { Apollo } from "apollo-angular";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Credentials, LoginContext, IUser } from "./authentication.model";
import { logIn } from "./mutation";

const credentialsKey = "credentials";

const validPasswordToken = gql`
  mutation validPasswordToken($resetPasswordToken: String!) {
    validPasswordToken(resetPasswordToken: $resetPasswordToken)
  }
`;

const resetPassword = gql`
  mutation resetPassword($resetPasswordToken: String!, $newPassword: String!) {
    resetPassword(
      resetPasswordToken: $resetPasswordToken
      newPassword: $newPassword
    )
  }
`;

const requestPassword = gql`
  mutation requestPassword($user_email: String!) {
    requestPassword(user_email: $user_email)
  }
`;

declare interface Me {
  me: IUser;
}

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private _credentials: Credentials | null;
  authenticated = false;
  authenticationState = new BehaviorSubject<any>({});

  constructor(private readonly apollo: Apollo, private router: Router) {
    const savedCredentials =
      sessionStorage.getItem(credentialsKey) ||
      localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
      this.authenticationState.next(this._credentials);
    }
  }

  authenticate(identity: IUser) {
    this.authenticated = identity !== null;
  }

  identity(): Observable<IUser> {
    return this.apollo
      .watchQuery<any>({
        query: gql`
          query {
            me {
              data {
                id
                fName
                lName
                email
                role {
                  id
                  description
                  name
                  permissions
                }
              }
            }
          }
        `,
      })
      .valueChanges.pipe(map((res) => res.data.me.data));
  }

  login(context: LoginContext): Observable<Credentials | any> {
    const user: Credentials = {
      phone: context.phone,
      token: "",
      role: {},
    };

    return this.apollo
      .mutate({
        mutation: logIn,
        variables: {
          input: context,
        },
      })
      .pipe(
        map(({ data }: any) => {
          user.token = data.emailOrPhonePasswordLogin.data.token;
          user.role = data.emailOrPhonePasswordLogin.data.role;
          this.setCredentials(user);
          this.apollo.getClient().resetStore();
          return user;
        })
      );
  }

  logout(): Observable<boolean> {
    this.setCredentials();
    this.authenticate(null);
    this.apollo.getClient().resetStore();
    return of(true);
  }

  getToken(): string {
    const lsCredentials: Credentials | null = JSON.parse(
      localStorage.getItem(credentialsKey)
    );
    const ssCredentials: Credentials | null = JSON.parse(
      sessionStorage.getItem(credentialsKey)
    );
    let token: string;

    if (!!lsCredentials) {
      token = lsCredentials.token;
    } else if (!!ssCredentials) {
      token = ssCredentials.token;
    }

    return token;
  }

  redirectLogoutOnSessionExpired() {
    this.router.navigate(["/access-denied"]);
  }

  isAuthenticated(): boolean {
    return !!this.credentials;
  }

  hasAnyAuthority(authorities: string[], role: {}): boolean {
    return this.hasAnyAuthorityDirect(authorities, role);
  }

  hasAnyAuthorityDirect(authorities: {}[], role: any): boolean {
    if (!this.isAuthenticated()) {
      return false;
    }

    return authorities.includes(role);
  }

  get credentials(): Credentials | null {
    return this._credentials;
  }

  private setCredentials(credentials?: Credentials, rememberMe?: boolean) {
    this._credentials = credentials || null;

    if (this._credentials) {
      const storage = rememberMe ? localStorage : sessionStorage;
      storage.setItem(credentialsKey, JSON.stringify(this._credentials));
    } else {
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
    }

    this.authenticationState.next(this._credentials);
  }
  getAuthenticatedState(): Observable<any> {
    return this.authenticationState.asObservable();
  }

  requestPassword(email: string): Observable<boolean> {
    return this.apollo
      .mutate({
        mutation: requestPassword,
        variables: {
          user_email: email,
        },
      })
      .pipe(map(({ data }: any) => data.requestPassword));
  }

  validPasswordToken(resetPasswordToken: string): Observable<boolean> {
    return this.apollo
      .mutate({
        mutation: validPasswordToken,
        variables: {
          resetPasswordToken,
        },
      })
      .pipe(map(({ data }: any) => data.validPasswordToken));
  }

  resetPassword(
    resetPasswordToken: string,
    newPassword: string
  ): Observable<boolean> {
    return this.apollo
      .mutate({
        mutation: resetPassword,
        variables: {
          resetPasswordToken,
          newPassword,
        },
      })
      .pipe(map(({ data }: any) => data.resetPassword));
  }
}
