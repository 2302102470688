import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  NbSidebarModule,
  NbDatepickerModule,
  NbToastrModule,
  NbMenuModule,
  NbDialogModule,
} from "@nebular/theme";
import { CoreModule } from "./core";
import { HttpClientModule } from "@angular/common/http";
import { LayoutModule } from "./dashboard/layout";
import { GraphQLModule } from "./graphql.module";
import { TranslateModule } from "@ngx-translate/core";
import { MomentModule } from "ngx-moment";
import { CKEditorModule } from "ng2-ckeditor";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule.forRoot(),
    GraphQLModule,
    LayoutModule.forRoot(),
    NbMenuModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbToastrModule.forRoot(),
    TranslateModule.forRoot(),
    CKEditorModule,
    MomentModule,
    
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
