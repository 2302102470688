import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NbSidebarService, NbMenuService } from "@nebular/theme";
import { LayoutService } from "../services/layout.service";
import { AuthenticationService } from "src/app/core";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent {
  constructor(
    private readonly router: Router,
    private readonly sidebarService: NbSidebarService,
    private readonly layoutService: LayoutService,
    private readonly authService: AuthenticationService
  ) {}

  navigateHome(): void {
    this.router.navigate(["/dashboard/statistics"]);
  }

  toggleSidebar() {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();

    return false;
  }

  onLogout(): void {
    this.authService.logout();
    this.router.navigate(["auth", "login"]);
  }
}
