import { NgModule } from "@angular/core";
import { ApolloModule, Apollo } from "apollo-angular";
import { HttpLinkModule, HttpLink } from "apollo-angular-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { environment } from "../environments/environment";
import { HttpClientModule } from "@angular/common/http";
import { ApolloLink } from "apollo-link";
import { DefaultOptions } from "apollo-client";
import { AuthenticationService } from "./core";

const uri = environment.GRAPQHL_API_ENDPOINT;

@NgModule({
  exports: [ApolloModule, HttpLinkModule, HttpClientModule],
})
export class GraphQLModule {
  constructor(
    private readonly auth$: AuthenticationService,
    private readonly httpLink: HttpLink,
    private readonly apollo: Apollo
  ) {
    const http = httpLink.create({ uri });

    const authLink = new ApolloLink((operation, forward) => {
      const vm = this;
      operation.setContext(
        ({ headers = {}, localToken = vm.auth$.getToken() }: any) => {
          if (localToken) {
            headers["authorization"] = `Bearer ${localToken}`;
            headers["Accept"] = `charset=utf-8`;
          }

          operation.setContext({ useMultipart: true });

          return {
            headers,
          };
        }
      );

      return forward(operation);
    });

    const link = ApolloLink.from([authLink, http]);

    const cache = new InMemoryCache();

    const defaultOptions: DefaultOptions = {
      watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
      },

      query: {
        fetchPolicy: "network-only",
        errorPolicy: "all",
      },
    };

    this.apollo.create({
      link,
      cache,
      defaultOptions,
    });
  }
}
