import { Injectable } from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthenticationService } from "./authentication.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> {
    const authorities = route.data.authorities;
    if (!this.authenticationService.isAuthenticated()) {
      this.router.navigate(["/auth/login"]);
      return false;
    }
    return true;
  }

  checkLogin(
    authorities: string[],
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authenticationService.identity().pipe(
      map((account) => {
        if (!authorities || authorities.length === 0) {
          return true;
        }

        if (account) {
          if (
            this.authenticationService.hasAnyAuthority(
              authorities,
              account.role
            )
          ) {
            return true;
          }
        }

        this.router.navigate(["/auth/login"]);
        return false;
      })
    );
  }
}
